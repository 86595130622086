﻿import jwtInterceptor from '../shared/jwtInterceptor';

export default {
    async GetAllHouses() {
        return await jwtInterceptor.get('/admin/GetAllHouse')
    },
    async AddHouse(val) {
        return await jwtInterceptor.post('/admin/CreateNewHouse', val)
    },
    async GetHouseUsers(id) {
        return await jwtInterceptor.post('/admin/GetHouseUsers', { 'ID': id })
    },
    HouseInfoUpdate(values) {
        return jwtInterceptor.post('/admin/HouseInfoUpdate', values)
    },
    async UpdateUser(val) {
        return jwtInterceptor.post('/admin/UpdateUser', val)
    },
    async GetHomeConnections(value) {
        return jwtInterceptor.post('/admin/GetSRConnectionsByHouse', { 'ID': value })
    },
    async DeleteConn(id) {
        return await jwtInterceptor.post('/admin/DeleteConnection', { 'ID': id })
    },
    async GetAllUsers() {
        return await jwtInterceptor.get('/admin/GetAllUser')
    },
    async GetPackageList() {
        return await jwtInterceptor.get('/update/GetPackageList')
    },
    async DeletePackageFile(id) {
        return await jwtInterceptor.post('/update/DeletePackage', { 'ID': id })
    },
    async SavePackage(data) {
        return await jwtInterceptor.post('/update/SavePackage', data);
    },
    async Download(version) {
        return await jwtInterceptor.post('/update/Download', { 'Version': version }, { responseType: 'blob' })
    },
    async logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }
}