import { jwtDecrypt } from "../../shared/jwtHelper";
import { tokenAlive } from "../../shared/jwtHelper";
import axios from '../../shared/axios'

const state = () => ({
  authData: {
    token: "",
    refreshToken: "",
    tokenExp: "",
    userId: "",
    userName: "",
  },
  loginStatus: "",
});

const getters = {
  getAuthData(state) {
    return state.authData;
  },
  getLoginStatus(state) {
    return state.loginStatus;
  },
  isTokenActive(state) {
    if (!state.authData.tokenExp) {
      return false;
    }
    return tokenAlive(state.authData.tokenExp);
  },
};

const actions = {
  async login({ commit }, payload) {
    const response = await axios
      .post("/jwt/LoginDash", payload)
      .catch((err) => {
        console.log(err);
      });
    if (response && response.data) {
      commit("saveTokenData", response.data);
      commit("setLoginStatu", "success");
    } else {
      commit("setLoginStatu", "failed");
    }
  },
  async refreshToken(context) {
    const { state, commit, dispatch } = context
    const payload = {
      access_token: state.authData.token,
      refresh_token: state.authData.refreshToken,
    };
    var response = await axios.post("/jwt/RefreshToken", payload);

    commit('saveTokenData', response.data);
    dispatch('autoRefresh', response.data)
  },

  autoRefresh(context, credentials) {
    const { state, commit, dispatch } = context
    const { token } = state.authData
    const { exp } = jwtDecrypt(token)
    const now = Date.now() / 1000 // exp is represented in seconds since epoch
    let timeUntilRefresh = exp - now
    timeUntilRefresh -= (1 * 60)  // Refresh 1 minutes before it expires
    const refreshTask = setTimeout(() => dispatch('refreshToken', credentials), timeUntilRefresh * 1000)
    commit('refreshTask', refreshTask) // In case you want to cancel this task on logout
  },
};

const mutations = {
  saveTokenData(state, data) {

    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);

    const jwtDecodedValue = jwtDecrypt(data.access_token);
    const newTokenData = {
      token: data.access_token,
      refreshToken: data.refresh_token,
      tokenExp: jwtDecodedValue.exp,
      userId: jwtDecodedValue.sub,
      userName: jwtDecodedValue.sub,//username,
    };
    state.authData = newTokenData;
  },
  setLoginStatu(state, value) {
    state.loginStatus = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}