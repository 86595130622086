<template>
  <DataTable :value="houseUserData" responsiveLayout="scroll" v-model:selection="selectedrow" dataKey="userID" @rowSelect="houseUserRowSelected"
             selectionMode="single" :paginator="true" :rows="5" :filters="filters" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
             :rowsPerPageOptions="[5,10,15]" :loading="isloadingUsers">
    <template #header>
      <div class="p-d-flex p-jc-between p-ai-center">
        <h4 class="p-m-0">House user Details</h4>
        <span class="p-input-icon-left">
            <IconField>
                <InputIcon class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
            </IconField>
        </span>
      </div>
    </template>
    <template #empty>
      No house information found.
    </template>
    <template #loading>
      Loading house information data. Please wait.
    </template>
    <Column field="userID" header="UserID" :sortable="true"></Column>
    <Column field="email" header="Email" :sortable="true"></Column>
    <Column field="firstName" header="First Name" :sortable="true"></Column>
    <Column field="lastName" header="Last Name" :sortable="true"></Column>
    <Column field="isActivated" header="Activated" :sortable="true" headerStyle="text-align: center;" bodyStyle="text-align: center;">
      <template #body="slotProps">
        <span :class="'housedata-badge status-' + slotProps.data.isActivated">{{$filters.formatActiveState(slotProps.data.isActivated)}}</span>
      </template>
    </Column>
    <Column field="isGlobalAdmin" header="Global Admin" :sortable="true" headerStyle="text-align: center;" bodyStyle="text-align: center;">
      <template #body="slotProps">
        <span :class="'housedata-badge status-' + slotProps.data.isGlobalAdmin">{{$filters.formatYesNo(slotProps.data.isGlobalAdmin)}}</span>
      </template>
    </Column>
    <Column field="isUserAdmin" header="User Admin" :sortable="true" headerStyle="text-align: center;" bodyStyle="text-align: center;">
      <template #body="slotProps">
        <span :class="'housedata-badge status-' + slotProps.data.isUserAdmin">{{$filters.formatYesNo(slotProps.data.isUserAdmin)}}</span>
      </template>
    </Column>
    <Column field="isSync" header="Sync'd" :sortable="true" headerStyle="text-align: center;" bodyStyle="text-align: center;">
      <template #body="slotProps">
        <span :class="'housedata-badge status-' + slotProps.data.isSync">{{$filters.formatYesNo(slotProps.data.isSync)}}</span>
      </template>
    </Column>
    <Column field="markedDel" header="markedDel" :sortable="true" headerStyle="text-align: center;" bodyStyle="text-align: center;">
      <template #body="slotProps">
        <span :class="'housedata-badge status-' + slotProps.data.markedDel">{{$filters.formatYesNo(slotProps.data.markedDel)}}</span>
      </template>
    </Column>
    <Column field="createdOn" header="Created On" :sortable="true" dataType="date" headerStyle="width: 12%">
      <template #body="slotProps">
        {{$filters.formatDate(slotProps.data.createdOn)}}
      </template>
    </Column>
    <Column field="updatedOn" header="Updated On" :sortable="true" dataType="date" headerStyle="width: 12%">
      <template #body="slotProps">
        {{$filters.formatDate(slotProps.data.updatedOn)}}
      </template>
    </Column>
  </DataTable>
  <br />
  <Panel header="User Info" v-if="selectedHouseUser !== null">
    <form @submit.prevent>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-4">
          <label for="userID">userID</label>
          <InputText type="text" class="form-control" id="userID" v-model="selectedHouseUser.userID" readonly />
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="email">Email</label>
          <InputText type="text" class="form-control" id="email" v-model="selectedHouseUser.email" readonly />
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="firstName">First Name</label>
          <InputText type="text" class="form-control" id="firstName" v-model="selectedHouseUser.firstName" :readonly="selectedHouseUser.email=='admin@claysys.com'" />
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="lastName">Last Name</label>
          <InputText type="text" class="form-control" id="lastName" v-model="selectedHouseUser.lastName" :readonly="selectedHouseUser.email=='admin@claysys.com'" />
        </div>
        <div class="p-field-checkbox p-col-12 p-md-4">
          <Checkbox id="isActivated" :binary="true" Value="true" v-model="selectedHouseUser.isActivated" :disabled="selectedHouseUser.email=='admin@claysys.com'" />
          <label for="isActivated">Activated</label>
        </div>
        <div class="p-field-checkbox p-col-12 p-md-4">
          <Checkbox class="form-control" disabled="true" id="isGlobalAdmin" :binary="true" Value="true" v-model="selectedHouseUser.isGlobalAdmin" />
          <label for="isGlobalAdmin">GlobalAdmin</label>
        </div>
        <div class="p-field-checkbox p-col-12 p-md-4">
          <Checkbox class="form-control" id="isUserAdmin" :binary="true" Value="true" v-model="selectedHouseUser.isUserAdmin" :disabled="selectedHouseUser.email=='admin@claysys.com'" />
          <label for="isUserAdmin">UserAdmin</label>
        </div>
        <div class="p-field-checkbox p-col-12 p-md-4">
          <Checkbox class="form-control" id="isSync" :binary="true" Value="true" v-model="selectedHouseUser.isSync" :disabled="selectedHouseUser.email=='admin@claysys.com'" />
          <label for="isSync">Sync'd</label>
        </div>
        <div class="p-field-checkbox p-col-12 p-md-4">
          <Checkbox class="form-control" id="markedDel" :binary="true" Value="true" v-model="selectedHouseUser.markedDel" :disabled="selectedHouseUser.email=='admin@claysys.com'" />
          <label for="markedDel">Marked for Delete</label>
        </div>
        <div class="p-col-6 p-offset-4 p-md-2">
          <Button class="btn btn-primary button" @click="HouseUserUpdate" label="Update"></Button>
        </div>
        <div class="p-col-6 p-md-2">
          <Button class="p-button-raised" @click="HouseUserCancel" label="Cancel"></Button>
        </div>

      </div>
    </form>
  </Panel>
  <br />
</template>
<script>
  import PostService from '../services/post-services';
    import { FilterMatchMode } from '@primevue/core/api';

  export default {
    name: "UserDetails",
    props: {
      house: {}
    },
    data() {
      return {
        isloadingUsers: true,
        selectedHouseUser: null,
        selectedrow: null,
        houseUserData: null,
        filters: {},
      };
    },
    watch: {
      house: function (newVal) {
            this.GetHouseUsers(newVal);
            this.selectedHouseUser = null;
      }
    },
    created() {
      this.initFilters();
    },
    mounted() {
      if (this.house != null)
        this.GetHouseUsers(this.house);
      else
        this.GetAllUsers();
    },

    methods: {
      houseUserRowSelected(event) {
        this.selectedHouseUser = JSON.parse(JSON.stringify(event.data));
      },
      HouseUserUpdate() {
        this.UpdateUser();
      },
      HouseUserCancel() {
        this.selectedHouseUser = null;
      },
      async GetHouseUsers(house) {
        PostService.GetHouseUsers(house.houseID).then(response => {
          this.houseUserData = response.data;
          this.houseUserData.forEach(user => { user.createdOn = new Date(user.createdOn); user.updatedOn = new Date(user.updatedOn) });
        }).catch(function (error) {
          alert(error);
        })
        this.isloadingUsers = false;
      },
      async UpdateUser() {
        PostService.UpdateUser(this.selectedHouseUser).then(response => {
          if (response.data) {
            alert("Updated!");
            if (this.house != null)
              this.GetHouseUsers(this.house);
            else
              this.GetAllUsers();
            this.selectedHouseUser = null;
            this.selectedrow = null;
          }
        }).catch(function (error) {
          alert(error);
        });
      },
      async GetAllUsers() {
        PostService.GetAllUsers().then(res => {
          this.houseUserData = res.data;
          this.houseUserData.forEach(user => { user.createdOn = new Date(user.createdOn); user.updatedOn = new Date(user.updatedOn) });
        }).catch(function (error) {
          alert("some error for getting data", error);
        });
        this.isloadingUsers = false;
      },
      initFilters() {
        this.filters = {
          'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        }
      },
    }
  }
</script>