<template>
    <Dialog v-model:visible="ispopvisible" modal header="Edit Profile" :style="{ width: '35rem' }">
        <template #header>
            <div class="inline-flex items-center justify-center gap-2">
                <span class="font-bold whitespace-nowrap">House Information</span>
            </div>
        </template>
        <div>
            <form @submit.prevent="saveHouseInfo">
                <div class="flex flex-col gap-2 mb-3">
                    <label for="houseID" class="font-semibold w-24">House ID *</label>
                    <InputText id="houseID" class="flex-auto" autocomplete="off" v-model="newHouse.houseID" v-on:input="validateNumber" maxlength="12" required="true" />
                    <small id="houseID-help">Enter the house id. Make sure its a 12 digit number.</small>
                </div>
                <div class="flex items-center gap-4 mb-3">
                    <label for="buildingName" class="font-semibold w-24">Building Name</label>
                    <InputText id="buildingName" class="flex-auto" autocomplete="off" v-model="newHouse.buildingName" maxlength="30" />
                </div>
                <div class="flex items-center gap-4 mb-3">
                    <label for="houseNumber" class="font-semibold w-24">House Number</label>
                    <InputText id="houseNumber" class="flex-auto" autocomplete="off" v-model="newHouse.houseNumber" maxlength="7" />
                </div>
                <div class="flex items-center gap-4 mb-3">
                    <label for="houseSqFeet" class="font-semibold w-24">Sq Feet</label>
                    <InputText id="houseSqFeet" class="flex-auto" autocomplete="off" v-model="newHouse.houseSqFeet" maxlength="7" />
                </div>
                <div class="flex items-center gap-4 mb-3">
                    <label for="ebConsumerNo" class="font-semibold w-24">Electricity Consumer No.</label>
                    <InputText id="ebConsumerNo" class="flex-auto" autocomplete="off" v-model="newHouse.ebConsumerNo" maxlength="10" />
                </div>
                <div class="flex items-center gap-4 mb-3">
                    <label for="waterConsumerNo" class="font-semibold w-24">Water Consumer No.</label>
                    <InputText id="waterConsumerNo" class="flex-auto" autocomplete="off" v-model="newHouse.waterConsumerNo" maxlength="10" />
                </div>
                <div class="flex items-center justify-end gap-4 mb-3" style="justify-content: center;">
                    <Button type="button" label="Cancel" severity="secondary" @click="ispopvisible = false"></Button>
                    <Button type="submit" label="Save"></Button>
                </div>
            </form>
        </div>
    </Dialog>
    <DataTable :value="houseInfoDatas" selectionMode="single" v-model:selection="selectedrow" responsiveLayout="scroll" dataKey="houseID" @rowSelect="houseInfoRowSelected"
               :paginator="true" :rows="5" :filters="filters" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,15]" :loading="isHouseLoading" sortField="houseNumber" :sortOrder="1">
        <template #header>
            <!-- class="p-datatable-houseInfo" -->
            <div class="p-d-flex p-jc-between p-ai-center">
                <h4 class="p-m-0">House Information</h4>
                <span class="icon-field-container">
                    <IconField class="icon-field-right">
                        <InputIcon class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </IconField>
                    <Button @click="openModal()" label="New"></Button>
                </span>
            </div>
        </template>
        <template #empty>
            No house information found.
        </template>
        <template #loading>
            Loading house information data. Please wait.
        </template>
        <Column field="houseID" header="House ID" :sortable="true"></Column>
        <Column field="houseNumber" header="House Number" :sortable="true"></Column>
        <Column field="houseSqFeet" header="House sq.feet" :sortable="true"></Column>
        <Column field="isActive" header="Active" :sortable="true" headerStyle="width: 8%; text-align: center;" bodyStyle="text-align: center;">
            <template #body="slotProps">
                <span :class="'housedata-badge status-' + slotProps.data.isActive">{{$filters.formatActiveState(slotProps.data.isActive)}}</span>
            </template>
        </Column>
        <Column field="isDeviceConnected" header="Device Connected" :sortable="true" headerStyle="width: 12%; text-align: center;" bodyStyle="text-align: center;">
            <template #body="slotProps">
                <span :class="'housedata-badge status-' + slotProps.data.isDeviceConnected">{{$filters.formatdDeviceState(slotProps.data.isDeviceConnected)}}</span>
            </template>
        </Column>
        <Column field="createdOn" header="Created On" :sortable="true" dataType="date" headerStyle="width: 12%">
            <template #body="slotProps">
                {{$filters.formatDate(slotProps.data.createdOn)}}
            </template>
        </Column>
        <Column field="updatedOn" header="Updated On" :sortable="true" dataType="date" headerStyle="width: 12%">
            <template #body="slotProps">
                {{$filters.formatDate(slotProps.data.updatedOn)}}
            </template>
        </Column>
    </DataTable>
    <br />

    <form @submit.prevent>
        <card v-if="selectedHouse !== null" class="flex gap-4 ">
            <template #title>House Details</template>
            <template #content>
                <div class="flex-auto">
                    <label for="HouseID" class="font-bold block mb-2">House ID</label>
                    <InputText type="text" class="form-control" id="HouseID" v-model="selectedHouse.houseID" readonly disabled />
                      </div>
                <div class="flex-auto mb-2">
                    <label for="buildingName" class="font-bold block">Building Name</label>
                    <InputText type="text" class="form-control" id="buildingName" v-model="selectedHouse.buildingName" v-tooltip="'Enter building name'" />
                </div>
                <div class="flex-auto mb-2">
                    <label for="HouseNumber" class="font-bold block">House Number</label>
                    <InputText type="text" class="form-control" id="HouseNumber" v-model="selectedHouse.houseNumber" />
                </div>
                <div class="flex-auto">
                    <label for="houseSqFeet" class="font-bold block mb-2">Sq Feet</label>
                    <InputText type="text" class="form-control" id="houseSqFeet" v-model="selectedHouse.houseSqFeet" />
                </div>
                <div class="flex-auto">
                    <label for="ebConsumerNo" class="font-bold block mb-2">Electricity Consumer No.</label>
                    <InputText type="text" class="form-control" id="ebConsumerNo" v-model="selectedHouse.ebConsumerNo" />
                </div>
                <div class="flex-auto">
                    <label for="waterConsumerNo" class="font-bold block mb-2">Water Consumer No.</label>
                    <InputText type="text" class="form-control" id="waterConsumerNo" v-model="selectedHouse.waterConsumerNo" />
                </div>
                <div class="flex-auto">
                    <label for="Active" class="font-bold block mb-4">Active</label>
                    <div class="flex items-center">
                        <RadioButton v-model="selectedHouse.isActive" inputId="Active" name="Active" value="true" required />
                        <label for="Active" class="ml-2">Active</label>
                    </div>
                    <div class="flex items-center">
                        <RadioButton v-model="selectedHouse.isActive" inputId="Inactive" name="Inactive" value="false" />
                        <label for="Inactive" class="ml-2">Inactive</label>
                    </div>
                </div>
                <div class="flex-auto">
                    <label for="currentVersion" class="font-bold block mb-2">Current Version.</label>
                    <InputText type="text" class="form-control" id="currentVersion" v-model="selectedHouse.currentVersion" />
                </div>
                <div class="flex-auto">
                    <label for="availableVersion" class="font-bold block mb-2">Available Version.</label>
                    <InputText type="text" class="form-control" id="availableVersion" v-model="selectedHouse.availableVersion" />
                </div>
            </template>
            <template #footer>
                <div class="flex gap-4 mt-1">
                    <Button class="w-full" outlined @click="HouseInfoCancel()" severity="secondary" label="Cancel"></Button>
                    <Button class="w-full" @click="HouseInfoUpdate()" label="Update"></Button>
                </div>
            </template>
        </card>
    </form>

    <br />
    <HouseDetails :house="selectedHouse" v-if="selectedHouse !== null"></HouseDetails>
</template>
<style scoped>
    .icon-field-container {
        display: flex;
        justify-content: flex-end; /* Aligns child elements to the right */
        width: 100%;
    }

    .icon-field-right {
        display: flex;
        align-items: center;
    }

    .w-24 {
        width: 6rem;
    }
</style>
<script>
    import { ref } from "vue";
    import ModalComponent from "../components/ModalComponent.vue";
    import HouseDetails from '../components/HouseDetails.vue'
    import PostService from '../services/post-services';
    import { FilterMatchMode } from '@primevue/core/api';

    export default {
        name: "HouseInfo",
        components: {
            HouseDetails,
            ModalComponent
        },
        data() {
            return {
                isHouseLoading: true,
                state: [{ name: 'Active', code: 'true' }, { name: 'In-Active', code: 'false' }],
                newHouse: {
                    houseID: '',
                    buildingName: '',
                    houseNumber: '',
                    houseSqFeet: '',
                    ebConsumerNo: '',
                    waterConsumerNo: ''
                },
                selectedHouse: null,
                selectedrow: null,
                houseInfoDatas: null,
                filters: {},
                ispopvisible: ref(false),
            };
        },
        created() {
            this.initFilters();
        },
        mounted() {
            this.getAllHouses();
        },
        methods: {
            validateNumber(event) {
                const regex = /^[0-9]+$/;
                if (!regex.test(event.target.value)) {
                    event.target.value = '';
                }
            },
            openModal() {
                this.ispopvisible = true;
            },
            closeModal() {
                this.ispopvisible = false;
            },
            saveHouseInfo() {
                var isValid = false;
                if (this.newHouse.houseID.trim() == "") {
                    alert('Enter a valid House number');
                }
                else if (this.newHouse.houseID.length != 12) {
                    alert('Enter a valid 12 House number');
                }
                else
                    isValid = true;

                if (isValid) {
                    PostService.AddHouse(this.newHouse).then(res => {
                        this.getAllHouses();
                    }).catch(function (error) {
                        alert("some error for getting data", error);
                    });

                    this.ispopvisible = false;
                }

            },
            getAllHouses() {
                PostService.GetAllHouses().then(res => {
                    this.houseInfoDatas = res.data;
                    this.houseInfoDatas.forEach(house => {
                        house.createdOn = new Date(house.createdOn);
                        house.updatedOn = new Date(house.updatedOn);
                        house.isActive = house.isActive.toString();
                    });
                }).catch(function (error) {
                    alert("some error for getting data", error);
                });
                this.isHouseLoading = false;
            },
            houseInfoRowSelected(event) {
                this.selectedHouse = JSON.parse(JSON.stringify(event.data))
            },
            HouseInfoCancel() {
                this.selectedHouse = null;
            },
            HouseInfoUpdate() {
                if (this.selectedHouse.isActive == "true") {
                    this.selectedHouse.isActive = true;
                }
                else {
                    this.selectedHouse.isActive = false;
                }
                PostService.HouseInfoUpdate(this.selectedHouse).then(res => {
                    alert("updated", res);
                    this.selectedHouse = null;
                    this.getAllHouses();
                }).catch(function (error) {
                    alert("error updating house info table", error);
                });
            },
            initFilters() {
                this.filters = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                }
            },

        },

    };
</script>

<style scoped>

    > > > .p-paginator .p-paginator-current {
        margin-left: auto;
    }
</style>
<style>

    .flex-col {
        flex-direction: column;
    }

    .p-button {
        padding: 0.329rem .5rem !important;
        font-size: .8rem !important;
    }

    .p-link {
        font-size: .8rem !important;
    }

    .p-datatable .p-datatable-header {
        padding: 0.257rem 1rem !important;
    }

    .p-panel .p-panel-header {
        padding: 0.557rem 1rem !important;
    }

    .p-component {
        font-size: .8rem !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0.471rem 0.457rem !important;
    }

    .p-datatable .p-datatable-thead > tr > th {
        padding: 0.471rem 0.457rem !important;
        font-weight: 600 !important;
    }

    .p-inputtext {
        font-size: .85rem !important;
    }

    .p-field > label {
        margin-bottom: 0.1rem !important;
    }

</style>