<template>
  <DataTable :value="houseConData" responsiveLayout="scroll" dataKey="connectionID" v-model:selection="selectedConnrow"
             selectionMode="single" :paginator="true" :rows="3" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
             :rowsPerPageOptions="[3,6,9]">
    <template #header>
      <div class="p-d-flex p-jc-between p-ai-center">
        <h4 class="p-m-0">House Connections</h4>
      </div>
    </template>
    <template #empty>
      No connection information found.
    </template>
    <template #loading>
      Loading connection information data. Please wait.
    </template>
    <Column field="connectionID" header="Connection ID" :sortable="true"></Column>
    <Column field="connectionType" header="Connection Type" :sortable="true"></Column>
    <Column field="createdOn" header="Created On" :sortable="true" dataType="date">
      <template #body="slotProps">
        {{$filters.formatDate(slotProps.data.createdOn)}}
      </template>
    </Column>
    <Column header="Delete" headerStyle="width: 4%">
      <template #body="slotProps">
        <Button type="button" icon="fa fa-trash" @click="deleteConn(slotProps.data)"></Button>
      </template>
    </Column>
  </DataTable>
  <br />
  <HouseUsers :house="house"></HouseUsers>
</template>

<script>
  import HouseUsers from '../components/UserInfo.vue'
  import PostService from '../services/post-services'

  export default {
    name: "HouseDetails",
    props: {
      house: {}
    },
    components: {
      HouseUsers,
    },
    data: function () {
      return {
        selectedConnrow: null,
        houseConData: null,
      }
    },
    watch: {
      house: function (newVal) {
        this.GetHouseCon(newVal);
      }
    },
    mounted() {
      this.GetHouseCon(this.house);
    },
    methods: {
      deleteConn(data) {
        this.DeleteConn(data.connectionID);
      }, 
      async GetHouseCon(house) {
        PostService.GetHomeConnections(house.houseID).then(response => {
          this.houseConData = response.data;
          this.houseConData.forEach(user => { user.createdOn = new Date(user.createdOn); });
        }).catch(function (error) {
          alert(error);
        })
      },    
      async DeleteConn(id) {
        PostService.DeleteConn(id).then(response => {
          if (response.data) {
            alert('Deleted Successfully');
            this.GetHouseCon(this.house);
          }
        }).catch(function (error) {
          alert(error);
        })
      }
    }
  };
</script>
<style scoped>
  >>> .p-paginator .p-paginator-current {
    margin-left: auto;
  }
</style>
<style>

  header {
    font-size: 14px;
  }

  body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--surface-a);
    font-family: apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 400;
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
  }
</style>