<template>
    <UserDetails :house="house"></UserDetails>
  <br />
</template>
<script>
  import UserDetails from '../components/UserDetails.vue'
  export default {
    name: "UserInfo",
    props: {
      house: {}
    },
    components: {
      UserDetails,
    },
  }
</script>