<template>
  <div style="background-color: #2a2a2e; text-align: right; color: white; height: 50px ;padding: 9px;">
    <div style="font-size:16px; ">
      <Avatar label="A" icon="pi pi-user" style="background-color:#2196F3; color: #ffffff" shape="circle" />
      {{gettersAuthData.userName}}
    </div>
  </div>
  <div id="main" :class="{ 'active' : isMenuCollapsed }">
    <div class="main-content">
      <router-view />
    </div>
    <sidebar-menu :menu='menu' width=200px @update:collapsed="onToggleCollapse">
      <template v-slot:header>
        <div style="text-align:center;color: white" v-if="!isMenuCollapsed">

        </div>
      </template>
    </sidebar-menu>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from 'vuex'
  export default {
    data() {
      return {
        isMenuCollapsed: false,
        menu: [
          {
            header: 'Main Navigation',
            hiddenOnCollapse: true
          },
          {
            href: '/admin',
            title: 'Dashboard',
            icon: 'fa fa-chart-area'
          },
          {
            href: '/admin/HouseInfo',
            title: 'Home Info 360',
            icon: 'fa fa-home'
          },
          {
            href: '/admin/UserInfo',
            title: 'User Info 360',
            icon: 'fa fa-user'
          },
          {
            href: '/admin/upload',
            title: 'Packages',
            icon: 'fa fa-upload'
          },
          {
            href: '/admin/signout',
            title: 'Sign-Out',
            icon: 'fa fa-sign-out-alt'
          },
        ]
      }
    },
    computed: {
      ...mapGetters('auth', {
        gettersAuthData: 'getAuthData'
      })
    },
    mounted() {
      this.refreshToken();
    },
    methods: {
      ...mapActions('auth', {
        actionRefreshToken: 'refreshToken'
      }),

      async refreshToken() {
        this.actionRefreshToken();
      },

      onToggleCollapse(collapsed) {
        this.isMenuCollapsed = collapsed;
      }
    }
  }
</script>
<style>
  #main.active {
    margin-left: 80px;
  }

  #main {
    margin-left: 220px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .v-sidebar-menu .vsm--link_level-1 .vsm--icon {
    padding: 7px;
    width: 30px !important;
    height: 30px !important;
    background-color: #63637d !important;
  }
</style>