<template>
  <div></div>
</template>
<script>
  import PostService from '../services/post-services'

  export default {
    mounted() {
      PostService.logout();
      this.$router.push('/');

    }
  }

</script>