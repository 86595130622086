<template>
  <router-view></router-view>
</template>

<script>

  export default {
    name: 'App',
    components: {},
    
  }
</script>

<style>
  #app {
     font-family: apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    color: #2c3e50;
    font-size: 12px;
  }

  .housedata-badge {
    border-radius: 5px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.65rem;
    letter-spacing: .3px;
  }

    .housedata-badge.status-false {
      background-color: #ffcdd2;
      color: #c63737;
    }

    .housedata-badge.status-true {
      background-color: #c8e6c9;
      color: #256029;
    }
</style>
