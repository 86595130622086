<template>
  <div>
    <h1>Dashboard Page</h1>
    <div>

    </div>
    <div class="row">
      <div class="column">
        <KPI>
        </KPI>
      </div>
      <div class="column">
        <KPI>
        </KPI>
      </div>
      <div class="column">
        <KPI>
        </KPI>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <KPI>
        </KPI>
      </div>
      <div class="column">
        <KPI>
        </KPI>
      </div>
      <div class="column">
        <KPI>
        </KPI>
      </div>
    </div>
  </div>
</template>
<style>
  .row {
    display: flex;
  }

  .column {
    flex: 50%;
  }
</style>
<script>

  import { mapGetters } from 'vuex'
  import KPI from '@/components/graph/KPI.vue'
  export default {
    components: { KPI },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters('auth', {
        gettersAuthData: 'getAuthData'
      })
    }
  }
</script>