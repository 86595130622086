<template>
    <PieChart :data="chartData" />
</template>
<script>
  import { ref } from 'vue';
    import { PieChart } from 'vue-chart-3';
  export default {
    name: 'KPI',
    components: { PieChart },
    setup() {
      const chartData = ref({
        labels: ['A', 'B', 'C'],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"]
          }
        ]
      });

      return { chartData }
    }
  }
</script>