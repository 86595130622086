<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openUpload" />
        <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" />
      </template>
    </Toolbar>
    <DataTable :value="packagesData" responsiveLayout="scroll" v-model:selection="fileRowSelected" dataKey="fileID"
               :paginator="true" :rows="5" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,15]" :loading="isloadingFiles">
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
          <h4 class="p-m-0">Package Details</h4>
        </div>
      </template>
      <template #empty>
        No package information found.
      </template>
      <template #loading>
        Loading package information. Please wait.
      </template>
      <Column selectionMode="multiple" style="width: 3rem"></Column>
      <Column field="fileID" header="File ID"></Column>
      <Column field="version" header="version"></Column>
      <Column field="downloads" header="Download Count"></Column>
      <Column field="file" header="File">
        <template #body="slotProps">
          <span>
            <Button icon="pi pi-download" @click="download(slotProps.data.version)"></Button>
          </span>
        </template>
      </Column>

      <Column field="isActive" header="Active" headerStyle="text-align: center;" bodyStyle="text-align: center;">
        <template #body="slotProps">
          <span :class="'housedata-badge status-' + slotProps.data.isActive">{{$filters.formatYesNo(slotProps.data.isActive)}}</span>
        </template>
      </Column>
      <Column field="createdOn" header="Created On" dataType="date" headerStyle="width: 12%">
        <template #body="slotProps">
          {{$filters.formatDate(slotProps.data.createdOn)}}
        </template>
      </Column>
      <Column field="updatedOn" header="Updated On" dataType="date" headerStyle="width: 12%">
        <template #body="slotProps">
          {{$filters.formatDate(slotProps.data.updatedOn)}}
        </template>
      </Column>
      <Column :exportable="false" style="min-width:8%">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editPackage(slotProps.data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
        </template>
      </Column>
    </DataTable>

    <Dialog v-model:visible="uploadDialog" :style="{width: '450px'}" header="Upload Package" :modal="true" class="p-fluid">
      <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="p-fluid">
          <div class="p-field">
            <label for="version" :class="{'p-error':v$.packageData.version.$invalid && submitted}">Version Number</label>
            <InputText id="version" :class="{'p-invalid':v$.packageData.version.$invalid && submitted}" v-model="packageData.version" />
          </div>
          <div class="p-field">
            <label for="isActive">is Active :&nbsp;</label>
            <checkbox v-model="packageData.isActive" :binary="true" value="packageData.isActive" />
          </div>
        </div>
        {{v$.packageData.file_attached}}
        <FileUpload id="file" name="file[]" accept=".zip" :maxFileSize="100000000" label="Import" :customUpload="true"
                    mode="advanced" auto="true" chooseLabel="Import" class="p-mr-2 p-d-inline-block" @uploader="uploadFile">
          <template #empty>
            <p>Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
      </form>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-danger" @click="hideDialog" />
        <Button label="Save" icon="pi pi-check" class="p-button-success" @click="handleSubmit(!v$.$invalid)" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="packagesData">Are you sure you want to delete the file ?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false;  this.fileRowSelected =false" />
        <Button label="Yes" icon="pi pi-check" class="p-button-success" @click="DeleteSelectedPackage" />
      </template>
    </Dialog>

  </div>
</template>
<script>
  import PostService from '../services/post-services';
  import { useVuelidate } from "@vuelidate/core";
  import { required, } from "@vuelidate/validators"; //requiredIf
  // const file_attached = (vm) => (vm.file.length > 1);
  //todo:

  export default {
    name: "Upload",
    setup: () => ({ v$: useVuelidate() }),
    data() {
      return {
        file: null,
        isloadingFiles: false,
        packagesData: null,
        packageData: {
          fileID: 0,
          version: '',
          isActive: true
        },
        fileRowSelected: null,
        uploadDialog: false,
        deleteDialog: false,
        submitted: false,
        selectedData: null,
        singleDel: false
      }
    },
    validations() {
      return {
        packageData: {
          version: { required },
          isActive: { required },
          //file_attached: {
          //  required: requiredIf(function (nestedModel) {
          //    alert(nestedModel);
          //    return this.file != null; // New changes
          //  })
          //}
        },
      }
    },
    mounted() {
      this.GetPackageList();
    },
    methods: {
      uploadFile(event) {
        if (event.files.length > 1) {
          alert('Only one attachment allowed.')
          event.files.pop();
        }
        this.file = event.files[0];
      },
      openUpload() {
        this.submitted = false;
        this.uploadDialog = true;
      },
      hideDialog() {
        this.uploadDialog = false;
        this.submitted = false;
        this.packageData = false;
      },
      savePackage() {
        this.uploadDialog = false;
        this.submitted = true;
      },
      handleSubmit(isFormValid) {
        this.submitted = true;

        if (!isFormValid) {
          return;
        }

        const formData = new FormData()
        formData.append('FileContent', this.file)
        formData.append('FileID', this.packageData.fileID)
        formData.append('Version', this.packageData.version)
        formData.append('IsActive', this.packageData.isActive)

        PostService.SavePackage(formData).then((res) => {
          if (res.data) {
            this.GetPackageList();
            this.submitted = false;
            this.uploadDialog = false;
            this.packageData = null;
          }
          console.log(res);
        }).catch(function error() {
          alert("fail to upload");
        });
      },

      editPackage(packageData) {
        this.packageData = { ...packageData };
        this.openUpload();
      },
      async GetPackageList() {
        this.isloadingFiles = true
        PostService.GetPackageList().then(response => {
          this.packagesData = response.data;
          this.packagesData.forEach(pkg => { pkg.createdOn = new Date(pkg.createdOn); pkg.updatedOn = new Date(pkg.updatedOn); });
        }).catch(function (error) {
          alert(error);
        })
        this.isloadingFiles = false
      },
      confirmDelete(packageInfo) {
        this.singleDel = true;
        this.fileRowSelected = packageInfo;
        this.deleteDialog = true;
      },
      confirmDeleteSelected() {
        this.singleDel = false;
        this.deleteDialog = true;
      },
      download(data) {
        PostService.Download(data).then(res => {
          const blob = new Blob([res.data], { type: 'application/zip' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "Download"
          link.click()
          URL.revokeObjectURL(link.href)
          this.GetPackageList()
        }).catch(function error() {
          alert("error on download");
        });
      },

      DeleteSelectedPackage() {
        var fileId = 0;
        if (this.singleDel) {
          fileId = this.fileRowSelected.fileID
        }
        else {
          this.selectedData = this.packagesData.filter(val => this.fileRowSelected.includes(val));
          console.log("this.packagesData length", this.packagesData.length);
          fileId = this.selectedData[0].fileID;
        }

        PostService.DeletePackageFile(fileId).then((res) => {
          if (res.data) {
            alert("Deleted the File");
            this.GetPackageList();
          }
          else
            alert("Error on Deleting");
        }).catch(function error() {
          alert("Error on Deleting");
        });
        this.deleteDialog = false;
        this.fileRowSelected = null;
        this.singleDel = false;
      }
    }
  }
</script>