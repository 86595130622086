<template>
  <div class="container-login" style="background-image: url('/img/bg-01.jpg')">
    <div class="p-d-flex p-jc-center">
      <div class="card">
        <h3>Sign-In</h3>
        <form @submit.prevent="login(!v$.$invalid)" class="p-fluid">
          <div class="p-field">
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-envelope" />
              <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" @blur="v$.email.$touch" aria-describedby="email-error" />
              <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
            </div>
            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{error.$message}}</small>
              </span>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
          </div>
          <div class="p-field">
            <div class="p-float-label">
              <Password id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" :feedback="false" toggleMask>
              </Password>
              <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Password*</label>
            </div>
            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value', 'Password')}}</small>
          </div>
          <Button type="submit" label="Submit" class="p-mt-2" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { email, required } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import { mapActions, mapGetters } from 'vuex';

  export default {
    setup() {
      return { v$: useVuelidate() }
    },
    data() {
      return {
        email: '',
        password: '',
        submitted: false,
        showMessage: false
      }
    },
    validations() {
      return {
        email: {
          required,
          email
        },
        password: {
          required
        },
      }
    },
    computed: {
      ...mapGetters('auth', {
        getterLoginStatus: 'getLoginStatus'
      })
    },
    methods: {
      ...mapActions('auth', {
        actionLogin: 'login'
      }),
      async login(isFormValid) {
        this.submitted = true;
        if (!isFormValid) {
          return;
        }

        await this.actionLogin({ email: this.email, password: this.password });
        if (this.getterLoginStatus === 'success') {
          this.$router.push("Admin");
        } else {
          alert('failed to login')
        }
      },
      resetForm() {
        this.email = '';
        this.password = '';
        this.submitted = false;
      }
    }
  };
</script>
<style scoped>
  .p-fluid {
    margin-top: 30px;
  }

  .container-login {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card {
    width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 25px;
    height: 300px;
  }
</style>