<template>
    <div class="hello">
        <header class="header">
            <div class="container">
                <div class="row flexbox-center">
                    <div class="col-lg-10 col-md-9 col-6 sm-static">
                        <div class="mainmenu">
                            <ul id="primary-menu">
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="download-area ptb-90" data-v-38980878="">
            <div class="container" data-v-38980878="">
                <div class="row" data-v-38980878="">
                    <div class="col-lg-12" data-v-38980878="">
                        <div class="sec-title" data-v-38980878="">
                            <h2 data-v-38980878="">Download Available</h2><p data-v-38980878="">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt </p>
                        </div>
                    </div>
                </div><div class="row" data-v-38980878="">
                    <div class="col-lg-12" data-v-38980878="">
                        <ul data-v-38980878="">
                            <li data-v-38980878="">
                                <a href="#" class="download-btn flexbox-center" data-v-38980878="">
                                    <div class="download-btn-icon" data-v-38980878="">
                                        <i class="icofont icofont-brand-android-robot" data-v-38980878=""></i>
                                    </div>
                                    <div class="download-btn-text" data-v-38980878="">
                                        <p data-v-38980878="">Available on</p>
                                        <h4 data-v-38980878="">Android Store</h4>
                                    </div>
                                </a>
                            </li>
                            <li data-v-38980878="">
                                <a href="#" class="download-btn flexbox-center" data-v-38980878="">
                                    <div class="download-btn-icon" data-v-38980878="">
                                        <i class="icofont icofont-brand-apple" data-v-38980878=""></i>
                                    </div>
                                    <div class="download-btn-text" data-v-38980878="">
                                        <p data-v-38980878="">Available on</p>
                                        <h4 data-v-38980878="">Apple Store</h4>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./../assets/css/bootstrap.min.css"></style>
<style scoped src="./../assets/css/slicknav.min.css"></style>
<style scoped src="./../assets/css/style.css"></style>
<style scoped src="./../assets/css/site.css"></style>
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
